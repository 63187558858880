:root {
    --blue-100: #E3F2FD;
    --blue-200: #BBDEFB;
    --blue-300: #90CAF9;
    --blue-400: #64B5F6;
    --blue-500: #42A5F5;
    --blue-600: #2196F3;
    --blue-700: #1E88E5;
    --blue-800: #1976D2;
    --blue-900: #1565C0;
}

:root {
    --indigo-100: #E0E7FF;
    --indigo-200: #C7D2FE;
    --indigo-300: #A5B4FC;
    --indigo-400: #818CF8;
    --indigo-500: #6366F1;
    --indigo-600: #4F46E5;
    --indigo-700: #4338CA;
    --indigo-800: #3730A3;
    --indigo-900: #312E81;
}

:root {
    --navy-100: #E6E6FF;
    --navy-200: #C4C6FF;
    --navy-300: #A2A5FC;
    --navy-400: #8888FC;
    --navy-500: #6667AB;
    --navy-600: #4D4D99;
    --navy-700: #353586;
    --navy-800: #2A2A72;
    --navy-900: #20203E;
}

:root {
    --denim-100: #DBEAFE;
    --denim-200: #BFDBFE;
    --denim-300: #93C5FD;
    --denim-400: #60A5FA;
    --denim-500: #3B82F6;
    --denim-600: #2563EB;
    --denim-700: #1D4ED8;
    --denim-800: #1E40AF;
    --denim-900: #1E3A8A;
}

:root {
    --pacific-blue-100: #D0EFFF;
    --pacific-blue-200: #A2DFFF;
    --pacific-blue-300: #74CEFF;
    --pacific-blue-400: #46BDFF;
    --pacific-blue-500: #18ACFF;
    --pacific-blue-600: #0098E6;
    --pacific-blue-700: #0077CC;
    --pacific-blue-800: #0056B3;
    --pacific-blue-900: #004299;
}

:root {
    --star-command-blue-100: #D6E4FF;
    --star-command-blue-200: #ADC8FF;
    --star-command-blue-300: #84A9FF;
    --star-command-blue-400: #6690FF;
    --star-command-blue-500: #4D77FF;
    --star-command-blue-600: #335DFF;
    --star-command-blue-700: #1A43FF;
    --star-command-blue-800: #0029E2;
    --star-command-blue-900: #001DB3;
}
